<template>
  <div class="user-store-order mb-2">
    <b-card>
      <div
        v-if="orderDetails"
        class="row"
      >
        <div class="col-md-8 map-height-responsive">
          <div
            id="mapboxLeaflet"
            style="width: 100%; height: 100%"
          />
          <!-- <GmapMap
            :zoom="7"
            :center="center"
            style="width: 100%; height: 100%"
          >
            <DirectionsRenderer
              travel-mode="DRIVING"
              :origin="startLocation"
              :destination="endLocation"
            />
          </GmapMap> -->
        </div>
        <div
          class="col-md-4 pt-1 responsive-p-0"
          style="background: #f7f7f7"
        >
          <b-card
            style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 15px"
            class="card-body-p-10"
          >
            <div
              v-if="orderDetails && orderDetails.status === 'WAITING_FOR_PAYMENT' && isUser"
              class="col-md-12 text-center mb-1"
            >
              <button
                class="btn btn-primary"
                @click="redirectToPayment"
              >
                {{ $t('Pay Now...') }}
              </button>
            </div>
            <div
              v-if="orderDetails && orderDetails.cancelled_by"
              class="col-md-12 text-left mb-1 p-0"
            >
              <h4 class="text-danger">
                {{ $t('Order cancelled by: ') }}{{ orderDetails.cancelled_by }}
              </h4>
              <p class="text-danger">
                {{ $t('Reason: ') }}{{ orderDetails.cancel_reason }}
              </p>
            </div>
            <div
              v-if="orderDetails && orderDetails.status"
              class="col-md-12 text-left mb-1 p-0"
            >
              <h4>{{ $t('Order status: ') }}{{ $superApp.actions.replaceUnderscore(orderDetails.status) }}</h4>
            </div>
            <div class="order-details">
              <p class="small mb-05">
                {{ $t('ORDER DETAILS') }}
              </p>
              <p class="text-black mb-0">
                {{ orderDetails.store_order_invoice_id }}
              </p>
              <hr>
            </div>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('DELIVERY LOCATION') }}
              </p>
              <p class="mb-25">
                {{ $t('Flat No') }}: {{ deliveryLocation.flat_no }}
              </p>
              <p class="mb-25">
                {{ $t('Street') }}: {{ deliveryLocation.street }}
              </p>
              <p class="mb-0">
                {{ deliveryLocation.map_address }}
              </p>
              <hr>
            </div>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('ITEMS') }}
              </p>
              <table class="table table-borderless">
                <tr
                  v-for="(cart, index) of cartDetails"
                  :key="index"
                >
                  <td
                    class="pl-0 text-black pt-pb-5 w-100"
                    :class="{ 'border-bottom': index != cartDetails.length - 1 }"
                  >
                    <div class="d-grid grid-columns-template-wrapper">
                      <p class="mb-0">
                        {{ cart.product.item_name }}
                      </p>
                      <p class="mb-0 text-right">
                        {{ cart.product.item_price }} {{ userCurrency }} x {{ cart.quantity }}
                      </p>
                      <p class="mb-0 text-right">
                        {{ userCurrency }}{{ cart.item_price }}
                      </p>
                    </div>
                    <p
                      v-if="cart.cartaddon && cart.cartaddon.length > 0"
                      class="mb-0"
                    >
                      ({{ cart.cartaddon.length }} {{ $t('addons') }})
                    </p>
                    <div
                      v-if="cart.cartaddon && cart.cartaddon.length > 0"
                      class="addons-wrapper"
                    >
                      <div
                        v-for="(addon, counter) of cart.cartaddon"
                        :key="counter"
                        class="d-grid grid-columns-template-wrapper"
                      >
                        <p class="mb-0">
                          {{ addon.addon_name }}
                        </p>
                        <p class="mb-0 text-right">
                          {{ addon.addon_price }} {{ userCurrency }} x {{ cart.quantity }}
                        </p>
                        <p class="mb-0 text-right">
                          {{ userCurrency }}{{ addon.addon_price }}
                        </p>
                      </div>
                    </div>
                    <p
                      v-if="getVariations(cart.product_data).length > 0"
                      class="mb-0 mt-1 font-weight-bolder"
                    >
                      {{ $t('Other details') }}
                    </p>
                    <ul
                      v-if="getVariations(cart.product_data).length > 0"
                      style="padding-left: 15px;"
                      class="mb-0"
                    >
                      <li
                        v-for="(addon, count) of getVariations(cart.product_data)"
                        :key="count"
                      >
                        {{ addon[0] }}: {{ addon[1] }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>

              <hr>
            </div>
            <div class="trip-fare">
              <p class="small mb-05">
                {{ $t('RECEIPT') }}
              </p>
              <table class="table table-borderless">
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Cart Subtotal') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ calculateOrderTotal }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Shop Offer') }}
                  </td>
                  <td
                    v-if="orderInvoice.discount <= 0"
                    class="text-black pt-pb-5 text-right"
                  >
                    {{ orderInvoice.shop_discount }} {{ userCurrency }}
                  </td>
                  <td
                    v-if="orderInvoice.discount > 0"
                    class="text-black pt-pb-5 text-right"
                  >
                    {{ orderInvoice.discount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Promocode Discount') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.promocode_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ orderDetails && orderDetails.user && orderDetails.user.country_code == '91' ? 'GST' : 'VAT' }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.tax_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Shop Package Charge') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.store_package_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="storeType !== 'FOOD'">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Shipping & Handling') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.delivery_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="storeType === 'FOOD'">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Delivery Charge') }}
                  </td>
                  <td
                    v-if="orderInvoice.delivery_amount"
                    class="text-black pt-pb-5 text-right"
                  >
                    {{ orderInvoice.delivery_amount }} {{ userCurrency }}
                  </td>
                  <td
                    v-else
                    class="text-black pt-pb-5 text-right"
                  >
                    FREE
                  </td>
                </tr>
                <!-- <tr v-if="orderInvoice.wallet_amount > 0">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Wallet') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.wallet_amount }} {{ userCurrency }}
                  </td>
                </tr> -->
              </table>

              <div class="relative mt-2">
                <hr>
                <b-badge
                  pill
                  variant="primary"
                  class="border-intersection"
                >
                  {{ orderInvoice.payable }} {{ userCurrency }}
                </b-badge>
              </div>
            </div>
            <div
              v-if="orderInvoice"
              class="wrap-service-actions d-flex mt-2"
            >
              <a class="mb-0 action-info bg-call">
                <img
                  :src="require('@/assets/images/app-icons/Call_New.png')"
                  @click="openCall"
                >
              </a>
              <p
                class="mb-0 action-info bg-message cursor-pointer"
                @click="showChatMessage = true"
              >
                <img :src="require('@/assets/images/app-icons/Chat_New.png')">
              </p>
              <a class="mb-0 action-info bg-whatsapp">
                <img
                  :src="require('@/assets/images/app-icons/whatsapp_New.png')"
                  @click="openWhatsapp"
                >
              </a>
              <p
                v-if="orderDetails && orderDetails.status != 'COMPLETED' && orderDetails.status != 'CANCELLED'"
                class="mb-0 action-info bg-cancel"
              >
                <img
                  class="cursor-pointer"
                  :src="require('@/assets/images/app-icons/Cancel_New.png')"
                  @click="confirmCancel"
                >
              </p>
            </div>
            <hr v-if="!showImageViewHistory && orderHistories.length > 0">
            <div
              v-if="!showImageViewHistory && orderHistories.length > 0"
              class="order-history-wrapper"
            >
              <p class="small mb-05">
                {{ $t('History') }}
              </p>
              <app-timeline>
                <app-timeline-item
                  v-for="(item, index) of orderHistories"
                  :key="index"
                  :title="item.status + ' at ' + item.created_at"
                  :variant="index == orderDetails.history.length - 1 ? 'secondary' : 'primary'"
                />
              </app-timeline>
            </div>
          </b-card>
        </div>
      </div>
      <div
        v-if="orderDetails.agent"
        class="delivered-by mt-2"
      >
        <h5 class="mb-2">
          {{ $t('Delivery by') }}
        </h5>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-5">
                <b-media>
                  <template #aside>
                    <b-avatar
                      ref="previewEl"
                      :src="$helpers.imageHelper(orderDetails.agent.picture)"
                      size="50px"
                      rounded
                    />
                  </template>
                  <h4 class="mb-1">
                    {{ orderDetails.agent.first_name }} {{ orderDetails.agent.last_name }}
                  </h4>
                  <p class="mb-1">
                    {{ $t('Invoice Id') }}: {{ orderDetails.store_order_invoice_id }}
                  </p>
                </b-media>
              </div>
              <div class="col-md-3">
                <div class="d-flex address-lines mt-2 cursor-pointer">
                  <b-img
                    style="height: 40px"
                    :src="require('@/assets/images/app-icons/Call_New.png')"
                    @click="openAgent"
                  />
                  <p
                    class="mb-0 ml-2"
                    @click="openAgent"
                  >
                    {{ orderDetails.agent.mobile }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex address-lines mt-2 cursor-pointer">
                  <b-img
                    v-if="!isUser"
                    style="height: 40px"
                    :src="require('@/assets/images/app-icons/Chat_New.png')"
                    @click="showAgentChatMessage = true"
                  />
                  <b-img
                    v-else
                    style="height: 40px"
                    :src="require('@/assets/images/app-icons/Chat_New.png')"
                    @click="showUserAgentChatMessage = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <div
      v-if="showImageViewHistory"
      class="d-flex block-responsive"
    >
      <div
        v-for="(process, index) of orderProcess"
        :key="index"
        class="block-img-responsive d-flex justify-content-center flex-wrap flex-position relative"
      >
        <b-img
          v-if="index === 0"
          :class="{ active: requestState(['ORDERED', 'PROCESSING', 'STORECANCELLED', 'PROVIDEREJECTED', 'RECEIVED', 'SEARCHING', 'ASSIGNED', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-1.svg')"
        />
        <b-img
          v-if="index === 1"
          :class="{ active: requestState(['ACCEPTED', 'PROVIDEREJECTED', 'RECEIVED', 'PROCESSING', 'ASSIGNED', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-2.svg')"
        />
        <b-img
          v-if="index === 2"
          :class="{ active: requestState(['STARTED', 'PICKEDUP', 'REACHED', 'ASSIGNED', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-3.svg')"
        />
        <b-img
          v-if="index === 3"
          :class="{ active: requestState(['REACHED', 'PICKEDUP', 'ARRIVED', 'DELIVERED', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-4.svg')"
        />
        <b-img
          v-if="index === 4"
          :class="{ active: requestState(['PICKEDUP', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-5.svg')"
        />
        <b-img
          v-if="index === 5"
          :class="{ active: requestState(['DELIVERED', 'COMPLETED']) }"
          :src="require('@/assets/images/app-images/step-6.svg')"
        />
        <div class="d-block text-center">
          <p
            v-if="index === 0"
            class="mb-0"
          >
            {{ requestDate(['ORDERED', 'PROCESSING', 'STORECANCELLED', 'PROVIDEREJECTED', 'RECEIVED', 'SEARCHING', 'ASSIGNED']) }}
          </p>
          <p
            v-if="index === 1"
            class="mb-0"
          >
            {{ requestDate(['ACCEPTED', 'PROVIDEREJECTED', 'RECEIVED', 'PROCESSING', 'ASSIGNED']) }}
          </p>
          <p
            v-if="index === 2"
            class="mb-0"
          >
            {{ requestDate(['STARTED', 'PICKEDUP', 'REACHED', 'ASSIGNED']) }}
          </p>
          <p
            v-if="index === 3"
            class="mb-0"
          >
            {{ requestDate(['REACHED', 'PICKEDUP', 'ARRIVED', 'DELIVERED', 'COMPLETED']) }}
          </p>
          <p
            v-if="index === 4"
            class="mb-0"
          >
            {{ requestDate(['PICKEDUP']) }}
          </p>
          <p
            v-if="index === 5"
            class="mb-0"
          >
            {{ requestDate(['DELIVERED', 'COMPLETED']) }}
          </p>
          <b-badge
            pill
            variant="primary"
            class="mt-0"
          >
            {{ process.text }}
          </b-badge>
        </div>
      </div>
    </div>
    <b-modal
      id="chat-modal-popup"
      v-model="showChatMessage"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="showChatMessage = false"
    >
      <chat
        :chat-type="'ORDER'"
        :data-id="orderIdInfo"
        :user-name="userChatName"
        :customer-id="userCustomerId"
        :store-provider-id="storeProviderId"
        :provider-name="storeChatName"
      />
    </b-modal>
    <b-modal
      id="chat-agent-modal-popup"
      v-model="showAgentChatMessage"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="showAgentChatMessage = false"
    >
      <chat
        :chat-type="'ORDER'"
        :is-agent-chat="true"
        :data-id="orderIdInfo"
        :user-name="agentChatName"
        :customer-id="agentCustomerId"
        :store-provider-id="storeProviderId"
        :provider-name="storeChatName"
      />
    </b-modal>
    <b-modal
      id="chat-user-agent-modal-popup"
      v-model="showUserAgentChatMessage"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="showUserAgentChatMessage = false"
    >
      <chat
        :chat-type="'ORDER'"
        :is-user-chat="true"
        :data-id="orderIdInfo"
        :user-name="userChatName"
        :customer-id="userCustomerId"
        :store-provider-id="agentCustomerId"
        :provider-name="agentChatName"
      />
    </b-modal>
    <b-modal
      id="order-feedback-popup"
      v-model="showFeedbackModal"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="showFeedbackModal = false"
    >
      <feedback
        :request-id="orderIdInfo"
        :admin-service="'ORDER'"
        :user-rated="orderDetails.user_rated"
        :delivery-rated="orderDetails.delivery_rated"
        @review-posted="reviewPosted"
      />
    </b-modal>
    <b-modal
      v-model="showCancelOrderModal"
      :title="$t('Please enter cancel reason')"
      :no-close-on-backdrop="true"
      @ok="cancelTheOrder"
      @hidden="showCancelOrderModal = false"
    >
      <b-col md="12">
        <b-form-group :label="$t('Cancel Reason')">
          <b-form-input
            v-model="cancelReason"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { BCard, BBadge, BImg, BMedia, BAvatar, BFormGroup, BFormInput, BCol, BModal } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useUserUi } from './useUser'
import { useStoreUi } from '../store/useStore'
import Chat from '../shared/chat/Chat.vue'
import Feedback from '../shared/Feedback.vue'

export default {
  components: {
    Chat,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BCard,
    Feedback,
    AppTimeline,
    AppTimelineItem,
    BBadge,
    BImg,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      showFeedbackModal: false,
      showChatMessage: false,
      showAgentChatMessage: false,
      showUserAgentChatMessage: false,
      orderDetails: {},
      center: { lat: 45.508, lng: -73.587 },
      startLocation: null,
      endLocation: null,
      orderIdInfo: null,
      showCancelOrderModal: false,
      cancelReason: '',
      storeChatName: {},
      agentLocation: null,
      storeProviderId: '',
      agentMarker: null,
      mapbox: null,
      mapImageStore: {
        STORE: require('@/assets/images/app-icons/store_New.png'),
        AGENT: require('@/assets/images/app-icons/Agent_New.png'),
        HOUSE: require('@/assets/images/app-icons/house_New.png'),
      },
      intervalAgent: null,
    }
  },
  computed: {
    showImageViewHistory() {
      return !(this.$store.getters['app/windowSize'] <= 767)
    },
    calculateOrderTotal() {
      return this.cartDetails
        .map(x => x.total_item_price)
        .filter(x => x)
        .reduce((a, b) => a + b, 0)
    },
    agentChatName() {
      if (!this.orderDetails) return ''
      if (!this.orderDetails.agent) return ''
      return `${this.orderDetails.agent.first_name} ${this.orderDetails.agent.last_name}`
    },
    userChatName() {
      if (!this.orderDetails) return ''
      if (!this.orderDetails.user) return ''
      return `${this.orderDetails.user.first_name} ${this.orderDetails.user.last_name}`
    },
    userCustomerId() {
      if (!this.orderDetails) return ''
      if (!this.orderDetails.user) return ''
      return this.orderDetails.user.id
    },
    agentCustomerId() {
      if (!this.orderDetails) return ''
      if (!this.orderDetails.agent) return ''
      return this.orderDetails.agent.id
    },
    orderHistories() {
      if (!this.orderDetails) return []
      if (!this.orderDetails.history) return []
      return this.orderDetails.history
    },
    deliveryLocation() {
      if (!this.orderDetails) return {}
      if (!this.orderDetails.delivery_address) return {}
      if (this.orderDetails.delivery_address === 'null') return {}
      return JSON.parse(this.orderDetails.delivery_address)
    },
    cartDetails() {
      if (!this.orderDetails) return []
      if (!this.orderDetails.invoice) return []
      return JSON.parse(this.orderDetails.invoice.cart_details)
    },
    userCurrency() {
      const cart = this.cartDetails
      if (cart && cart[0] && cart[0].store) {
        return cart[0].store.currency_symbol
      }
      return ''
    },
    orderInvoice() {
      if (!this.orderDetails) return {}
      if (!this.orderDetails.invoice) return {}
      return this.orderDetails.invoice
    },
    isUser() {
      return window.SuperApp.getters.isUser()
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
    storeType() {
      if (this.orderDetails && this.orderDetails.store && this.orderDetails.store.storetype) {
        return this.orderDetails.store.storetype
      }
      return ''
    },
    orderProcess() {
      return [
        {
          text: 'ORDER',
        },
        {
          text: 'ACCEPTED',
        },
        {
          text: 'ASSIGNED',
        },
        {
          text: 'PICKED UP',
        },
        {
          text: 'IN TRANSIT',
        },
        {
          text: 'DELIVERED',
        },
      ]
    },
  },
  mounted() {
    this.orderIdInfo = this.$route.params.orderid ? this.$route.params.orderid : this.$route.params.orderId
    eventBus.$on('order-updated', payload => {
      // eslint-disable-next-line eqeqeq
      if (payload && payload.orderId == this.orderIdInfo && payload.service === 'ORDER') {
        this.reset()
        this.showInitialLoad()
        if (this.isUser) {
          eventBus.$emit('refresh-on-going-services')
        }
      }
    })
    eventBus.$on('new-order-viewed', orderId => {
      const location = `${window.location.origin}/user/store/order/${orderId}`
      window.history.pushState({}, '', location)
      this.orderIdInfo = orderId
      this.reset()
      this.showInitialLoad()
      if (this.isUser) {
        eventBus.$emit('refresh-on-going-services')
      }
    })
    this.showInitialLoad()
  },
  destroyed() {
    eventBus.$off('new-order-viewed')
    eventBus.$off('order-updated')
    if (window.orderDetailsRoutes) {
      window.orderDetailsRoutes = null
    }
    if (this.intervalAgent) {
      clearInterval(this.intervalAgent)
    }
  },
  methods: {
    getVariations(products) {
      if (!products) return []
      try {
        return Object.entries(JSON.parse(products))
      } catch (error) {
        return []
      }
    },
    redirectToPayment() {
      if (
        this.orderDetails
        && this.orderDetails.invoice
        && this.orderDetails.invoice.items
        && this.orderDetails.invoice.items[0]
        && this.orderDetails.invoice.items[0].store
        && this.orderDetails.invoice.items[0].store.slug
      ) {
        const { slug } = this.orderDetails.invoice.items[0].store
        this.$router.push({
          path: `/user/checkout-shop/${slug}?orderId=${this.orderIdInfo}&retry=1`,
        })
      }
    },
    reviewPosted(status) {
      if (status) {
        this.showFeedbackModal = false
        this.showInitialLoad()
      } else {
        this.showFeedbackModal = true
      }
    },
    getStoreId() {
      if (!this.orderDetails) return ''
      if (this.orderDetails.invoice && !this.orderDetails.order_invoice) {
        this.orderDetails.order_invoice = this.orderDetails.invoice
      }
      if (!this.orderDetails.order_invoice) return ''
      if (this.orderDetails.order_invoice.items && this.orderDetails.order_invoice.items.length > 0) {
        const storeInfo = this.orderDetails.order_invoice.items.find(x => x.store)
        if (storeInfo && storeInfo.store) {
          return `${storeInfo.store.id}`
        }
      }
      return ''
    },
    getStoreName() {
      if (!this.orderDetails) return ''
      if (this.orderDetails.invoice && !this.orderDetails.order_invoice) {
        this.orderDetails.order_invoice = this.orderDetails.invoice
      }
      if (!this.orderDetails.order_invoice) return ''
      if (this.orderDetails.order_invoice.items && this.orderDetails.order_invoice.items.length > 0) {
        const storeInfo = this.orderDetails.order_invoice.items.find(x => x.store)
        if (storeInfo && storeInfo.store) {
          return `${storeInfo.store.store_name}`
        }
      }
      return ''
    },
    openAgent() {
      if (this.orderDetails && this.orderDetails.agent && this.orderDetails.agent.mobile) {
        window.open(`tel:${this.orderDetails.agent.country_code ? this.orderDetails.agent.country_code : ''}${this.orderDetails.agent.mobile}`)
      }
    },
    openCall() {
      if (this.isStore) {
        if (this.orderDetails && this.orderDetails.user && this.orderDetails.user.mobile) {
          window.open(`tel:${this.orderDetails.user.country_code ? this.orderDetails.user.country_code : ''}${this.orderDetails.user.mobile}`)
        }
      } else if (this.orderDetails && this.orderDetails.pickup && this.orderDetails.pickup.contact_number) {
        window.open(`tel:${this.orderDetails.user.country_code ? this.orderDetails.user.country_code : ''}${this.orderDetails.pickup.contact_number}`)
      }
    },
    openWhatsapp() {
      if (this.isStore) {
        if (this.orderDetails && this.orderDetails.user && this.orderDetails.user.mobile) {
          window.open(`https://wa.me/${this.orderDetails.user.country_code ? this.orderDetails.user.country_code : ''}${this.orderDetails.user.mobile}/?text=Hello`)
        }
      } else if (this.orderDetails && this.orderDetails.pickup && this.orderDetails.pickup.contact_number) {
        window.open(`https://wa.me/${this.orderDetails.user.country_code ? this.orderDetails.user.country_code : ''}${this.orderDetails.pickup.contact_number}/?text=Hello`)
      }
    },
    reset() {
      if (window.orderDetailsRoutes) {
        window.orderDetailsRoutes = null
      }
      if (this.mapbox) {
        this.mapbox.remove()
      }
      this.startLocation = null
      this.endLocation = null
      this.orderDetails = {}
      this.mapbox = null
    },
    showInitialLoad() {
      showLoader()
      this.apiFunction(this.orderIdInfo)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderDetails = data.responseData
            if (!this.orderDetails) {
              showDangerNotification(this, 'No order found')
              return
            }
            if (this.orderDetails.status === 'COMPLETED' && (!this.orderDetails.user_rated || !this.orderDetails.delivery_rated) && this.isUser) {
              this.showFeedbackModal = true
            }
            if (!this.orderDetails.store) {
              this.orderDetails.store = this.orderDetails.pickup
            }
            if (!this.orderDetails.deliveryaddress) {
              this.orderDetails.deliveryaddress = this.orderDetails.delivery
            }
            this.storeChatName = this.getStoreName()
            this.storeProviderId = this.getStoreId()
            if (this.isStore) {
              this.orderDetails.invoice = this.orderDetails.order_invoice
            }
            if (this.orderDetails.pickup && this.orderDetails.delivery) {
              this.runDirection(`${this.orderDetails.pickup.latitude}, ${this.orderDetails.pickup.longitude}`, `${this.orderDetails.delivery.latitude}, ${this.orderDetails.delivery.longitude}`)
            } else {
              this.runDirection()
            }
            hideLoader()
            if (this.isStore) {
              this.getCurrentLocation()
              this.intervalAgent = setInterval(() => {
                this.getCurrentLocation()
              }, 1000 * 15)
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCurrentLocation() {
      if (!this.orderDetails) {
        return
      }
      if (!this.orderDetails.agent_id) {
        return
      }
      const { getLocationInfo } = useStoreUi()

      getLocationInfo(`?agent_id=${this.orderDetails.agent_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!this.agentLocation) {
              this.agentLocation = {}
            }
            if (data.responseData && data.responseData.latitude !== this.agentLocation.latitude && data.responseData.longitude !== this.agentLocation.longitude) {
              this.agentLocation = data.responseData
              if (this.agentLocation && this.agentLocation.latitude && this.agentLocation.longitude) {
                if (this.orderDetails.pickup && this.orderDetails.delivery) {
                  this.runDirection(
                    `${this.orderDetails.pickup.latitude}, ${this.orderDetails.pickup.longitude}`,
                    `${this.orderDetails.delivery.latitude}, ${this.orderDetails.delivery.longitude}`,
                    `${this.agentLocation.latitude}, ${this.agentLocation.longitude}`,
                  )
                }
              }
            } else {
              console.log('Driver at same location')
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    confirmCancel() {
      if (this.isStore || this.isUser) {
        this.$swal({
          title: this.$t('Are you sure?'),
          text: this.$t('You want to cancel the request?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.showCancelOrderModal = true
          }
        })
      }
    },
    cancelTheOrder() {
      if (this.isStore) {
        this.cancelStoreOrder()
      } else if (this.isUser) {
        this.cancelUserStoreOrder()
      }
    },
    cancelStoreOrder() {
      showLoader()
      const { storeCancelOrder } = useStoreUi()
      const formData = new FormData()
      formData.append('id', this.orderDetails.id)
      formData.append('cancel_reason', this.cancelReason)
      formData.append('store_id', this.orderDetails.store_id)
      formData.append('user_id', this.orderDetails.user_id)
      storeCancelOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            eventBus.$emit('store-sync-app', {
              refreshDashboard: true,
              storeId: window.SuperApp.getters.userInfo().id,
            })
            if (this.isStore) {
              this.$router.push({
                path: '/shop/dashboard',
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    cancelUserStoreOrder() {
      showLoader()
      const { userCancelOrderRequest } = useUserUi()
      userCancelOrderRequest({
        id: this.orderDetails.id,
        cancel_reason: this.cancelReason,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            eventBus.$emit('store-sync-app', {
              refreshDashboard: true,
              storeId: window.SuperApp.getters.userInfo().id,
            })
            eventBus.$emit('refresh-on-going-services')
            if (this.isUser) {
              this.$router.push({
                path: '/user/order',
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    apiFunction(orderId) {
      if (this.isUser) {
        const { userStoreOrder } = useUserUi()
        return userStoreOrder(orderId)
      }
      const { agentOrderDetail } = useStoreUi()
      return agentOrderDetail(orderId)
    },
    requestDate(state) {
      if (!this.orderDetails) return ''
      const statusHistory = this.orderHistories.find(x => state.includes(x.status))
      if (statusHistory) return statusHistory.created_at
      return ''
    },
    requestState(state) {
      if (!this.orderDetails) return false
      return state.includes(this.orderDetails.status) || this.orderHistories.find(x => state.includes(x.status))
    },
    runDirection(start, end, agent) {
      const urlRoutes = []
      if (start) urlRoutes.push(start)
      if (end) urlRoutes.push(end)
      if (agent) urlRoutes.push(agent)
      if (!this.mapbox) {
        console.log(window.L, 'window.L')
        this.mapbox = window.L.map('mapboxLeaflet').setView([-41.2858, 174.78682], 8)
        const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
        window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: `Map data &copy; ${mapLink}`,
          maxZoom: 18,
        }).addTo(this.mapbox)
      }
      if (urlRoutes.length >= 2) window.SuperApp.actions.createRouting(urlRoutes, [this.mapImageStore.STORE, this.mapImageStore.HOUSE, this.mapImageStore.AGENT], this.mapbox, 'orderDetailsRoutes')
      window.SuperApp.helper.resize()
    },
  },
}
</script>

<style scoped>
.pt-pb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.border-intersection {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  padding: 5px 10px !important;
}

.flex-position {
  padding-bottom: 30px;
  width: 100%;
}

.flex-position span {
  position: static;
  bottom: 0;
}

.flex-position img {
  width: 100%;
  height: 120px;
  opacity: 0.3;
}

.flex-position img + span {
  opacity: 0.3;
}

.flex-position img.active,
.flex-position img.active + span {
  opacity: 1;
}

.user-store-order table.table th,
.user-store-order table.table td {
  padding: 0.72rem 1rem;
  font-size: 14px;
}

.wrap-service-actions .action-info span {
  display: block;
  width: 100%;
}

.wrap-service-actions .action-info {
  width: 100%;
  text-align: center;
}

.wrap-service-actions .action-info:not(:last-child) {
  margin-right: 5px;
}

.wrap-service-actions img {
  width: 50px;
}

.grid-columns-template-wrapper {
  grid-template-columns: 50% 30% 20%;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .map-height-responsive {
    height: 400px;
    padding: 0;
    margin-bottom: 15px;
  }

  .responsive-p-0 {
    padding: 0 !important;
  }

  .card-body-p-10 .card-body {
    padding: 10px;
  }

  .block-responsive {
    display: block !important;
  }

  .block-img-responsive {
    display: inline-block !important;
    width: 48% !important;
    text-align: center;
  }

  .block-img-responsive span {
    position: static;
  }

  .wrap-service-actions .action-info {
    display: block;
    margin-bottom: 10px !important;
  }

  .wrap-service-actions {
    text-align: center;
  }

  .wrap-service-actions img {
    width: 35px;
  }

  .wrap-service-actions .action-info {
    width: 45%;
    display: inline-block;
  }
}
</style>
